@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: "Poppins", sans-serif;
    }

    body {
        margin: 0;
        background-color: #f8f9fa;
        padding: 0;
        font-family: "Poppins", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .dark {
        background-color: #171717;
    }
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 768px) {

}

@keyframes slideIn {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .animate-slideIn {
    animation: slideIn 0.3s ease-out;
  }

@keyframes slideOut {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }

  .animate-slideOut {
    animation: slideOut 0.3s ease-out;
  }


.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 50 !important;
}
.ReactModal__Content {
  max-width: 500px;
  margin: auto;
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
    max-width: 80%;
    max-height: 80%;
}

.modal-content img {
    max-width: 100%;
    max-height: 100%;
}

.chat-button {
    user-select: none; /* предотвращение выделения текста */
}

.message {
    user-select: none; /* предотвращение выделения текста */
}

.no-scroll {
    overflow: hidden;
}

@keyframes blob1 {
    0%, 100% {
        border-radius: 40% 60% 60% 40% / 40% 40% 60% 60%;
        transform: translate(0, 0) scale(1);
    }
    30% {
        border-radius: 50% 50% 70% 30% / 30% 50% 50% 70%;
        transform: translate(-20px, -30px) scale(1.05);
    }
    60% {
        border-radius: 30% 70% 50% 50% / 50% 30% 70% 50%;
        transform: translate(25px, 10px) scale(0.95);
    }
    90% {
        border-radius: 70% 30% 50% 50% / 50% 70% 30% 50%;
        transform: translate(-20px, 30px) scale(1);
    }
}

@keyframes blob2 {
    0%, 100% {
        border-radius: 40% 60% 60% 40% / 40% 40% 60% 60%;
        transform: translate(0, 0) scale(1);
    }
    25% {
        border-radius: 45% 55% 65% 35% / 35% 55% 55% 65%;
        transform: translate(-15px, -20px) scale(1.1);
    }
    50% {
        border-radius: 35% 65% 55% 45% / 45% 35% 65% 55%;
        transform: translate(20px, 5px) scale(0.9);
    }
    75% {
        border-radius: 60% 40% 50% 50% / 50% 60% 40% 50%;
        transform: translate(-15px, 20px) scale(1.05);
    }
}

@keyframes blob3 {
    0%, 100% {
        border-radius: 40% 60% 60% 40% / 40% 40% 60% 60%;
        transform: translate(0, 0) scale(1);
    }
    20% {
        border-radius: 45% 55% 60% 40% / 45% 45% 55% 55%;
        transform: translate(-10px, -15px) scale(1.05);
    }
    50% {
        border-radius: 35% 65% 55% 45% / 45% 35% 65% 55%;
        transform: translate(15px, 5px) scale(0.95);
    }
    80% {
        border-radius: 55% 45% 60% 40% / 40% 55% 45% 60%;
        transform: translate(-10px, 15px) scale(1.05);
    }
}

@keyframes blob4 {
    0%, 100% {
        border-radius: 40% 60% 60% 40% / 40% 40% 60% 60%;
        transform: translate(0, 0) scale(1);
    }
    20% {
        border-radius: 50% 50% 70% 30% / 30% 50% 50% 70%;
        transform: translate(-5px, -10px) scale(1.1);
    }
    50% {
        border-radius: 30% 70% 50% 50% / 50% 30% 70% 50%;
        transform: translate(10px, 5px) scale(0.9);
    }
    80% {
        border-radius: 60% 40% 50% 50% / 50% 60% 40% 50%;
        transform: translate(-5px, 10px) scale(1);
    }
}

.animate-blob1 {
    animation: blob1 8s infinite;
}

.animate-blob2 {
    animation: blob2 8s infinite;
}

.animate-blob3 {
    animation: blob3 8s infinite;
}

.animate-blob4 {
    animation: blob4 8s infinite;
}

/* Добавьте задержки для каждой фигуры */
.animation-delay-1000 {
    animation-delay: 1s;
}

.animation-delay-2000 {
    animation-delay: 2s;
}

.animation-delay-3000 {
    animation-delay: 3s;
}


.grid-layout {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Три колонки */
    grid-auto-rows: 150px; /* Базовая высота строк */
    gap: 8px; /* Расстояние между элементами */
}

.grid-layout > div.col-span-2 {
    grid-column: span 2; /* Растягивает на две колонки */
}

.grid-layout > div.row-span-2 {
    grid-row: span 2; /* Растягивает на две строки */
}

.chat-container {
    scroll-behavior: smooth;
}

input[type="range"] {
    accent-color: #8b5cf6; /* Фиолетовый */
}

@keyframes fade-in-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.animate-fade-in-up {
    animation: fade-in-up 0.5s ease-out;
}
