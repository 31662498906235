.swiper-pagination {
    position: relative;
    bottom: 0;
    margin-top: 20px;
    margin-bottom: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.swiper-pagination-bullet {
    background-color: #555555;
    width: 6px;
    height: 6px;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background-color: #6B46C1;
    width: 8px;
    height: 8px;
}
